const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://arimena.com.co' : 'http://192.168.100.106:3052',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://arimena.com.co' : 'http://192.168.100.106:3052',
    api: 'api/',
    apiSocket: 'arimena::1.2',
    nameDir: 'arimena',
    package: 'co.com.arimena.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyD117SIe9tX0kpbu2bFaFcmwXxXsTF-FZo',
    appName: 'Arimena',
    provider: '',
    colorPrimary: '#75D803',
    colorDark: '#0772F2',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'co.com.arimena.passenger',
    playStoreDriverId: 'co.com.arimena.driver',
    appStorePassengerId: '6505044335',
    appStoreDriverId: '6505044471',
    email: "Info@arimena.com",
};
export default config;
